import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import logo250 from '../assets/logos/logo250.webp';
const Home = () => {
  return (
    <div>
      <section className="hero">
      <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Votre partenaire pour l'intégration de l'IA générative en entreprise</h1>
        <p>Nos solutions sur mesure vous aident à automatiser vos processus, à gagner en efficacité et à innover dans votre secteur.</p>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>

      <section className="key-points">
        <div className="point">
          <h3>Expertise de pointe en IA générative</h3>
          <p>Nos experts en IA, data science et ingénierie logicielle maîtrisent les dernières avancées en matière d'IA générative et de NLP.</p>
        </div>
        <div className="point">
          <h3>Solutions sur mesure pour votre entreprise</h3>
          <p>Nous développons des solutions d'IA adaptées à vos enjeux métier spécifiques, en tenant compte de vos contraintes techniques et réglementaires.</p>
        </div>
        <div className="point">
          <h3>Accompagnement à chaque étape</h3>
          <p>De l'audit initial au déploiement et à la maintenance, nous vous accompagnons tout au long de votre projet d'intégration de l'IA générative.</p>
        </div>
      </section>

      <section className="services">
        <h2>Nos services</h2>
        <div className="service">
          <h3>Consulting IA</h3>
          <p>Nous évaluons votre maturité digitale, identifions les cas d'usage pertinents et définissons votre feuille de route IA générative.</p>
          <ul>
            <li>Évaluation de la maturité digitale et de la capacité d'intégration de l'IA</li>
            <li>Identification des cas d'usage à forte valeur ajoutée</li>
            <li>Analyse des coûts, bénéfices et impacts sur l'organisation</li>
            <li>Définition d'une feuille de route stratégique d'intégration de l'IA générative</li>
          </ul>
        </div>
        <div className="service">
          <h3>Intégration de solutions IA générative</h3>
          <p>Nous concevons, développons et déployons des solutions d'IA générative sur mesure pour automatiser vos processus et booster votre performance.</p>
          <ul>
            <li>Chatbots et agents conversationnels intelligents</li>
            <li>Automatisation des tâches répétitives et à faible valeur ajoutée</li>
            <li>Intégration fluide dans vos systèmes et workflows existants</li>
            <li>Mise en conformité avec les exigences réglementaires sur les données et la sécurité</li>
          </ul>
        </div>
      </section>

      <section className="about">
        <h2>Qui sommes-nous ?</h2>
        <p>BrainCorpAI est une startup fondée en 2023 par Kevin Lopez, Franck Ferrié et Laurent Ferrié, trois experts complémentaires en IA, entrepreneuriat et gestion d'entreprise. Notre mission est d'aider les entreprises à exploiter tout le potentiel de l'IA générative pour innover, gagner en efficacité et se développer.</p>
        <p>Nous croyons que l'IA générative est un moteur de transformation et de compétitivité pour les entreprises, au même titre que le cloud ou la data science. En démocratisant l'accès à ces technologies et en accompagnant leur adoption au sein des organisations, nous voulons contribuer à l'émergence d'une nouvelle ère de croissance et d'innovation tirée par l'IA.</p>
      </section>

      <section className="case-study">
        <div className="case-study-content">
          <h2>Automatisation du support client pour un e-commerçant</h2>
          <div className="case-study-stats">
            <div className="stat">
              <span className="stat-number">90%</span>
              <span className="stat-description">des demandes client gérées par l'IA</span>
            </div>
            <div className="stat">
              <span className="stat-number">x4</span>
              <span className="stat-description">augmentation des ventes</span>
            </div>
          </div>
          <p>Nous avons développé un agent conversationnel intelligent capable de gérer la majorité des demandes client sur Instagram, permettant à notre client de multiplier ses ventes.</p>
          <ul>
            <li>Compréhension des intentions d'achat dans les messages client</li>
            <li>Génération automatique de paniers et de réponses personnalisées</li>
            <li>Routage des demandes complexes vers les conseillers humains</li>
            <li>Apprentissage continu à partir des interactions client</li>
          </ul>
        </div>
      </section>

      <section className="cta">
        <h2>Prêt à révolutionner votre entreprise avec l'IA générative ?</h2>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
    </div>
  );
};
export default Home;
