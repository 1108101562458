// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .case-study {
    position: relative;
    padding: 60px 20px;
    background: linear-gradient(45deg, #007bff, #00bfff);
    color: #fff;
    clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);
  }
  
  .case-study-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .case-study h2 {
    font-size: 32px;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .case-study p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .case-study ul {
    list-style: none;
    padding-left: 30px;
  }
  
  .case-study li {
    position: relative;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .case-study li::before {
    content: "✓";
    position: absolute;
    left: -30px;
    top: 0;
    font-size: 24px;
  }
  

  .case-study-stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
  }
  
  .stat {
    text-align: center;
  }
  
  .stat-number {
    display: block;
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .stat-description {
    display: block;
    font-size: 18px;
    color: #fff;
    opacity: 0.8;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Customers.css"],"names":[],"mappings":";EACE;IACE,kBAAkB;IAClB,kBAAkB;IAClB,oDAAoD;IACpD,WAAW;IACX,kDAAkD;EACpD;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,2CAA2C;EAC7C;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,eAAe;EACjB;;;EAGA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,2CAA2C;EAC7C;;EAEA;IACE,cAAc;IACd,eAAe;IACf,WAAW;IACX,YAAY;EACd","sourcesContent":["\n  .case-study {\n    position: relative;\n    padding: 60px 20px;\n    background: linear-gradient(45deg, #007bff, #00bfff);\n    color: #fff;\n    clip-path: polygon(0 0, 100% 5%, 100% 95%, 0 100%);\n  }\n  \n  .case-study-content {\n    max-width: 800px;\n    margin: 0 auto;\n  }\n  \n  .case-study h2 {\n    font-size: 32px;\n    margin-bottom: 20px;\n    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);\n  }\n  \n  .case-study p {\n    font-size: 18px;\n    line-height: 1.6;\n    margin-bottom: 20px;\n  }\n  \n  .case-study ul {\n    list-style: none;\n    padding-left: 30px;\n  }\n  \n  .case-study li {\n    position: relative;\n    font-size: 16px;\n    line-height: 1.6;\n    margin-bottom: 10px;\n  }\n  \n  .case-study li::before {\n    content: \"✓\";\n    position: absolute;\n    left: -30px;\n    top: 0;\n    font-size: 24px;\n  }\n  \n\n  .case-study-stats {\n    display: flex;\n    justify-content: space-around;\n    margin-bottom: 40px;\n  }\n  \n  .stat {\n    text-align: center;\n  }\n  \n  .stat-number {\n    display: block;\n    font-size: 48px;\n    font-weight: bold;\n    color: #fff;\n    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);\n  }\n  \n  .stat-description {\n    display: block;\n    font-size: 18px;\n    color: #fff;\n    opacity: 0.8;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
