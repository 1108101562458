// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-privacy {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .terms-and-privacy h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .terms-and-privacy h2 {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  
  .terms-and-privacy p {
    font-size: 16px;
    margin-bottom: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Terms.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB","sourcesContent":[".terms-and-privacy {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 40px 20px;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n  }\n  \n  .terms-and-privacy h1 {\n    font-size: 32px;\n    margin-bottom: 20px;\n  }\n  \n  .terms-and-privacy h2 {\n    font-size: 24px;\n    margin-top: 40px;\n    margin-bottom: 15px;\n  }\n  \n  .terms-and-privacy p {\n    font-size: 16px;\n    margin-bottom: 15px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
