import React from 'react';
import { Link } from 'react-router-dom';
import logo250 from '../assets/logos/logo250.webp';
import './Terms.css';

const Terms = () => {
  return (
    
    <div className="terms-and-privacy">
        <section className="hero">
        <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Conditions d'utilisation</h1>
        </section>
      <p>En accédant et en utilisant le site web de BrainCorpAI, vous acceptez d'être lié par les présentes conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site web.</p>

      <h2>Propriété intellectuelle</h2>
      <p>Tous les contenus présents sur le site web de BrainCorpAI (textes, images, vidéos, logos, etc.) sont la propriété exclusive de BrainCorpAI ou de ses partenaires et sont protégés par les lois sur la propriété intellectuelle. Vous n'êtes pas autorisé à reproduire, modifier, distribuer ou utiliser ces contenus sans l'autorisation écrite préalable de BrainCorpAI.</p>

      <h2>Liens vers des sites tiers</h2>
      <p>Le site web de BrainCorpAI peut contenir des liens vers des sites web de tiers. BrainCorpAI n'exerce aucun contrôle sur ces sites et décline toute responsabilité quant à leur contenu ou leurs pratiques en matière de protection des données. L'accès à ces sites se fait sous votre seule responsabilité.</p>

      <h2>Limitation de responsabilité</h2>
      <p>BrainCorpAI s'efforce de maintenir les informations de son site web à jour et exactes. Cependant, BrainCorpAI ne peut garantir l'exactitude, l'exhaustivité ou la pertinence de ces informations. BrainCorpAI décline toute responsabilité pour tout dommage direct, indirect, consécutif ou spécial résultant de l'accès ou de l'utilisation de son site web.</p>

      <h2>Modification des conditions</h2>
      <p>BrainCorpAI se réserve le droit de modifier à tout moment les présentes conditions d'utilisation. Les modifications seront publiées sur cette page et entreront en vigueur dès leur publication. Il vous appartient de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.</p>

      <h2>Droit applicable et juridiction compétente</h2>
      <p>Les présentes conditions d'utilisation sont régies par le droit français. Tout litige relatif à l'interprétation ou à l'exécution de ces conditions sera soumis à la compétence exclusive des tribunaux français.</p>
    </div>
  );
};

export default Terms;