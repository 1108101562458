// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closed-source {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  margin-bottom: 100px;
}

.generative-ai,
.llm {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  
}

.generative-ai h2,
.llm h2,
.closed-source h2 {
  font-size: 32px;
  margin-bottom: 50px;
  margin-top: 50px;
  text-align: center;
}

.generative-ai p,
.llm p,
.closed-source p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  
}

.closed-source ul {
  list-style: none;
  margin-left: 0;
  padding-left: 30px;
}

.closed-source li {
  position: relative;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.closed-source li::before {
  content: "✓";
  position: absolute;
  left: -30px;
  top: 0;
  font-size: 24px;
  color: #00bfff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Expertise.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,uEAAuE;EACvE,oBAAoB;AACtB;;AAEA;;EAEE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;;AAEpB;;AAEA;;;EAGE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;;EAGE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;;AAErB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,eAAe;EACf,cAAc;AAChB","sourcesContent":[".closed-source {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 40px 20px;\n  background-color: #f5f5f5;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);\n  margin-bottom: 100px;\n}\n\n.generative-ai,\n.llm {\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 40px 20px;\n  \n}\n\n.generative-ai h2,\n.llm h2,\n.closed-source h2 {\n  font-size: 32px;\n  margin-bottom: 50px;\n  margin-top: 50px;\n  text-align: center;\n}\n\n.generative-ai p,\n.llm p,\n.closed-source p {\n  font-size: 18px;\n  line-height: 1.6;\n  margin-bottom: 20px;\n  \n}\n\n.closed-source ul {\n  list-style: none;\n  margin-left: 0;\n  padding-left: 30px;\n}\n\n.closed-source li {\n  position: relative;\n  font-size: 18px;\n  line-height: 1.6;\n  margin-bottom: 15px;\n}\n\n.closed-source li::before {\n  content: \"✓\";\n  position: absolute;\n  left: -30px;\n  top: 0;\n  font-size: 24px;\n  color: #00bfff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
