import React from 'react';
import { Link } from 'react-router-dom';
import Unanimity from '../assets/images/unanimity.png';
import './Unanimity.css';
import logo250 from '../assets/logos/logo250.webp';

const unanimityai = () => {
  return (
    <div>
      <section className="unanimity-hero">
      <img src={Unanimity} alt="UnanimityAI logo" className="unanimity-hero-logo" />
        <h1>UnanimityAI - Coming Soon</h1>
        <a href="https://unanimity.ai" target="_blank" rel="noopener noreferrer" className="unanimity-button">
            Découvrir UnanimityAI
            </a>
      </section>
        <section className="unanimity-study">
        <div className="unanimity-study-content">
        <h2>UnanimityAI - Révolutionnez vos interactions client</h2>
            <p>Déployez des agents IA pour automatiser et optimiser votre support client sur toutes vos plateformes.</p>
            <ul>
            <li>Centralisez en quelques clics les demandes clients de vos plateformes (WhatsApp, Instagram, Email).</li>
            <li>Créez votre agent IA automatiquement à partir de vos données et laissez notre application optimiser son comportement.</li>
            <li>Laissez votre agent IA gérer efficacement vos interactions clients. Il fera appel à vous seulement quand c'est nécessaire.</li>
            <li>Concentrez-vous sur des tâches à forte valeur ajoutée. Ne perdez plus de temps avec le support client.</li>
            </ul>
        </div>
      </section>
    </div>
      );
};

export default unanimityai;
