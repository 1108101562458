import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Solutions from './pages/Solutions';
import Customers from './pages/Customers';
import Expertise from './pages/Expertise';
import News from './pages/News';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Consulting from './pages/Consulting';
import Unanimity from './pages/Unanimity';
import Legal from './pages/LegalNotice';
function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/unanimityai" element={<Unanimity />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
