import React from 'react';
import logo250 from '../assets/logos/logo250.webp';
import './Terms.css';
const Privacy = () => {
  return (
    <div className="terms-and-privacy">
        
        <section className="hero">
        <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Politique de confidentialité</h1>
        </section>
      <p>Chez BrainCorpAI, nous attachons une grande importance à la protection de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, stockons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web et nos services.</p>

      <h2>Collecte des données</h2>
      <p>Nous collectons les données personnelles que vous nous fournissez volontairement, par exemple lorsque vous remplissez un formulaire de contact ou vous inscrivez à notre newsletter. Ces données peuvent inclure votre nom, votre adresse email, votre numéro de téléphone et toute autre information que vous choisissez de nous communiquer.</p>

      <h2>Utilisation des données</h2>
      <p>Nous utilisons les données que nous collectons dans le but de vous fournir les services que vous avez demandés, de répondre à vos questions, de vous tenir informé de nos actualités et de nos offres, et d'améliorer notre site web et nos services. Nous ne vendons ni ne louons vos données personnelles à des tiers.</p>

      <h2>Stockage et protection des données</h2>
      <p>Nous stockons vos données personnelles sur des serveurs sécurisés et nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger ces données contre tout accès, modification ou divulgation non autorisés. Nous conservons vos données uniquement pendant la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées.</p>

      <h2>Vos droits</h2>
      <p>Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification, d'effacement et de portabilité de vos données personnelles. Vous pouvez également vous opposer au traitement de vos données ou demander la limitation de ce traitement. Pour exercer ces droits, veuillez nous contacter à l'adresse indiquée ci-dessous.</p>

      <h2>Contact</h2>
      <p>Si vous avez des questions concernant notre politique de confidentialité ou le traitement de vos données personnelles, vous pouvez nous contacter à l'adresse suivante : privacy@braincorpai.com.</p>
    </div>
  );
};

export default Privacy;
