import React from 'react';
import { Link } from 'react-router-dom';
import './Expertise.css';
import logo250 from '../assets/logos/logo250.webp';
const Expertise = () => {
  return (
    <div>
      <section className="hero">
      <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Notre expertise en IA générative</h1>
        <p>Chez BrainCorpAI, nous sommes à la pointe des dernières avancées en matière d'IA générative et de grands modèles de langage (LLM). Notre équipe d'experts en IA, data science et ingénierie logicielle maîtrise les techniques les plus innovantes pour créer des solutions d'IA générative performantes et adaptées à vos enjeux métier.</p>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>

      <section className="generative-ai">
        <h2>L'IA générative, une révolution technologique</h2>
        <p>L'IA générative désigne une classe de modèles d'IA capables de générer de nouveaux contenus (textes, images, vidéos, code...) à partir d'exemples et de directives. Cette approche révolutionne de nombreux domaines, de la création de contenu à l'automatisation des processus en passant par l'analyse de données.</p>
        <p>Chez BrainCorpAI, nous exploitons tout le potentiel de l'IA générative pour créer des solutions innovantes et à forte valeur ajoutée pour nos clients.</p>
        <h2>Les grands modèles de langage (LLM), clé de voûte de l'IA générative</h2>
        <p>Les grands modèles de langage (LLM) sont des modèles d'IA entraînés sur d'immenses corpus de textes, capables de comprendre et de générer du langage naturel de façon très performante. Ils sont à la base de nombreuses applications d'IA générative, des chatbots aux assistants de rédaction en passant par la traduction automatique.</p>
        <p>Chez BrainCorpAI, nous exploitons les LLM les plus avancés (GPT-4, Claude 3, CommandR+) et les techniques de fine-tuning et de prompt engineering pour adapter ces modèles à vos données et à vos cas d'usage spécifiques. Nous veillons à optimiser la performance et la qualité des résultats tout en garantissant la sécurité et la confidentialité de vos données.</p>
      </section>


      <section className="closed-source">
        <h2>Modèles closed-source : performance et scalabilité pour l'entreprise</h2>
        <p>Pour déployer des solutions d'IA générative à l'échelle de l'entreprise, nous privilégions les modèles closed-source des grands fournisseurs cloud (OpenAI, Anthropic, Google...). Ces modèles présentent en effet plusieurs avantages clés :</p>
        <ul>
          <li>Une performance et une qualité de génération supérieures, grâce à des volumes de données d'entraînement et une puissance de calcul inégalés</li>
          <li>Une scalabilité et une disponibilité optimales, grâce aux infrastructures cloud de ces fournisseurs</li>
          <li>Des API sécurisées et des options de fine-tuning pour adapter ces modèles à vos besoins spécifiques</li>
          <li>Un écosystème riche d'outils et de ressources pour accélérer le développement et le déploiement</li>
        </ul>
        <p>Bien que les modèles open-source soient intéressants pour la recherche et l'expérimentation, nous pensons que les modèles closed-source sont aujourd'hui le meilleur choix pour des applications d'IA générative critiques en entreprise.</p>
      </section>

      <section className="cta">
        <h2>Prêt à révolutionner votre entreprise avec l'IA générative ?</h2>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
    </div>
  );
};

export default Expertise;