import React from 'react';
import logo250 from '../assets/logos/logo250.webp';
import './Terms.css';

const Legal = () => {
  return (
    <div className="terms-and-privacy">
        
        <section className="hero">
        <img src={logo250} alt="Logo de BrainCorpAI" className="hero-logo" />
        <h1>Informations légales</h1>
        </section>

      <h2>Éditeur</h2>
      <p>Conformément aux dispositions de l'article 6-I-1 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, l'éditeur du site https://www.braincorpai.com/ est :</p>
      <p>BrainCorp, société par actions simplifiée au capital de 7 500 EUR, inscrite au Registre du Commerce et des Sociétés (R.C.S.) d'Antibes sous le numéro 919 141 762, dont le siège social se situe au 3 PASSAGE DU DOCTEUR CALMETTE, 06800 CAGNES-SUR-MER, FRANCE.</p>
      <p>Adresse email de contact : contact@braincorp.fr.</p>

      <h2>Directeur de la publication</h2>
      <p>Le directeur de la publication du site à l'adresse https://www.braincorpai.com/ est Kevin Lopez, Directeur Technique de BrainCorp.</p>

      <h2>Hébergement</h2>
      <p>La société chargée de l'hébergement du site https://www.Braincorpai.com/ est Cloudflare Inc., 101 Townsend St, San Francisco, CA 94107, États-Unis d'Amérique.</p>

      <h2>Crédits</h2>
      <p>Ce site web est réalisé avec d'excellents logiciels open-source.</p>

      <h2>Suivi</h2>
      <p>Ce site web n'utilise pas de cookies pour vous suivre.</p>

      <h2>Email</h2>
      <p>Ce site web stocke votre email uniquement pour vous contacter ultérieurement. Nous ne vendons ni n'utilisons votre email d'une autre manière.</p>

    </div>
  );
};
export default Legal;
