import React from 'react';
import { Link } from 'react-router-dom';
import './Consulting.css';
import logo250 from '../assets/logos/logo250.webp';
const Consulting = () => {
  return (
    <div>
      <section className="hero">
      <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Nos services de consulting en IA générative</h1>
        <p>Bénéficiez de notre expertise pour intégrer l'IA générative de manière stratégique et responsable dans votre entreprise.</p>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
  

      <section className="importance">
        <h2>L'importance d'une intégration réfléchie de l'IA générative</h2>
        <p>L'IA générative offre un potentiel immense pour transformer et optimiser les processus métier, mais son intégration doit être abordée de manière stratégique et responsable pour en tirer le meilleur parti :</p>
        <ul>
          <li>Alignement avec les objectifs et les enjeux de l'entreprise</li>
          <li>Prise en compte des impacts sur les employés, les clients et les parties prenantes</li>
          <li>Respect des réglementations et des principes éthiques</li>
          <li>Préparation de l'infrastructure et des compétences internes</li>
          <li>Mise en place d'une gouvernance et d'un suivi adaptés</li>
        </ul>
      </section>

      <section className="approach">
        <h2>Notre approche de consulting</h2>
        <p>Chez BrainCorpAI, nous vous accompagnons à chaque étape de votre projet d'intégration de l'IA générative :</p>
        <ol>
          <li>
            <strong>Audit et diagnostic</strong>
            <p>Nous analysons votre maturité digitale, vos processus et vos données pour identifier les opportunités d'application de l'IA générative.</p>
          </li>
          <li>
            <strong>Définition de la stratégie</strong>
            <p>Nous vous aidons à définir une vision claire et une feuille de route pragmatique pour intégrer l'IA générative, en alignement avec vos objectifs business.</p>
          </li>
          <li>
            <strong>Sélection des solutions</strong>
            <p>Nous vous guidons dans le choix des modèles, des architectures et des fournisseurs les plus adaptés à vos besoins et contraintes.</p>
          </li>
          <li>
            <strong>Accompagnement de la mise en œuvre</strong>
            <p>Nous vous assistons dans le déploiement, l'intégration et l'adoption des solutions d'IA générative, en veillant à la qualité et à la performance.</p>
          </li>
          <li>
            <strong>Formation et conduite du changement</strong>
            <p>Nous sensibilisons et formons vos équipes aux enjeux et aux bonnes pratiques de l'IA générative pour faciliter son appropriation.</p>
          </li>
        </ol>
      </section>

      <section className="cta">
        <h2>Prêt à intégrer l'IA générative de manière stratégique ?</h2>
        <Link to="/contact" className="button">
          <button className="hero-button">Échangeons sur votre projet !</button>
        </Link>
      </section>
    </div>
  );
};

export default Consulting;
