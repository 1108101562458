import React from 'react';
import { Link } from 'react-router-dom';
import logo250 from '../assets/logos/logo250.webp';
import './Customers.css';

const Customers = () => {
  return (
    <div>
      <section className="hero">
        <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Ils nous font confiance</h1>
        <p>Découvrez comment nos solutions d'IA générative ont transformé les activités de nos clients et boosté leur performance.</p>
        <Link to="/contact" className="button">
          <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>

      <section className="case-study">
        <div className="case-study-content">
          <h2>Automatisation du support client pour un e-commerçant</h2>
          <div className="case-study-stats">
            <div className="stat">
              <span className="stat-number">90%</span>
              <span className="stat-description">des demandes client gérées par l'IA</span>
            </div>
            <div className="stat">
              <span className="stat-number">x4</span>
              <span className="stat-description">augmentation des ventes</span>
            </div>
          </div>
          <p>Nous avons développé un agent conversationnel intelligent capable de gérer la majorité des demandes client sur Instagram, permettant à notre client de multiplier ses ventes.</p>
          <ul>
            <li>Compréhension des intentions d'achat dans les messages client</li>
            <li>Génération automatique de paniers et de réponses personnalisées</li>
            <li>Routage des demandes complexes vers les conseillers humains</li>
            <li>Apprentissage continu à partir des interactions client</li>
          </ul>
        </div>
      </section>

      <section className="cta">
        <h2>Prêt à révolutionner votre entreprise avec l'IA générative ?</h2>
        <Link to="/contact" className="button">
          <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
    </div>
  );
};

export default Customers;