import React, { useState } from 'react';
import Airtable from 'airtable';
import { Link } from 'react-router-dom';
import logo250 from '../assets/logos/logo250.webp';
import './Contact.css';
const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();  // Prevent default form submission behavior

    var base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API_KEY}).base('appXyv45a2Ukqh15X');

    base('siteweb').create([
      {
        "fields": {
          "Email": email,
          "Message":message,
        }
      }
    ], function(err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
      alert('Merci, nous reviendrons vers vous très prochainement !');
      setEmail('');
      setMessage('');
    });
  }

  return (
    <div>
      <section className="hero">
        <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Contactez-nous</h1>
        <p>Vous avez un projet d'IA générative en tête ? Vous souhaitez en savoir plus sur nos solutions et notre expertise ? N'hésitez pas à nous contacter, nous serons ravis d'échanger avec vous !</p>
      </section>
  
      <section className="contact-form">
        <h2>Chaque innovation commence par une conversation.</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Votre email :</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="message">Que pouvons nous faire ?</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            rows="6"  // Default row size, you can adjust this as needed
          />
          <button type="submit">Envoyer</button>
        </form>
      </section>
    </div>
  );
  
};

export default Contact;