import React from 'react';
import { Link } from 'react-router-dom';
import KevinImage from '../assets/images/kevin.webp';
import FranckImage from '../assets/images/franck.webp';
import LaurentImage from '../assets/images/laurent.webp';
import logo250 from '../assets/logos/logo250.webp';
import './Hero.css';
import './About.css';

const About = () => {
  return (
    <div>
      <section className="hero">
        <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Votre partenaire pour l'intégration de l'IA générative en entreprise</h1>
        <Link to="/contact" className="button">
          <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
      <section className="security">
      <h2>Notre équipe</h2>
      </section>

      <section className="team">
        <div className="team-member">
          <div className="photo-container">
            <img src={KevinImage} alt="Kevin Lopez" />
          </div>
          <h3>Kevin Lopez</h3>
          <p>Co-fondateur et CTO</p>
          <p>Expert en IA, deep learning et NLP. Ancien ingénieur de recherche au CNRS et doctorant en neurosciences computationnelles.</p>
        </div>
        <div className="team-member">
          <div className="photo-container">
            <img src={FranckImage} alt="Franck Ferrié" />
          </div>
          <h3>Franck Ferrié</h3>
          <p>Co-fondateur et CEO</p>
          <p>Entrepreneur et investisseur expérimenté. Gère la stratégie, le développement commercial et les partenariats de BrainCorpAI.</p>
        </div>
        <div className="team-member">
          <div className="photo-container">
            <img src={LaurentImage} alt="Laurent Ferrié" />
          </div>
          <h3>Laurent Ferrié</h3>
          <p>Co-fondateur et CFO</p>
          <p>Directeur financier et expert en gestion d'entreprise. Supervise la gestion financière, juridique et RH de BrainCorpAI.</p>
        </div>
      </section>
      <section className="company-info">
        <h2>Qui sommes-nous ?</h2>
        <p>BrainCorpAI est une startup innovante fondée en 2023 par Kevin Lopez, Franck Ferrié et Laurent Ferrié, trois experts complémentaires en IA, entrepreneuriat et gestion d'entreprise. Basés sur la Côte d'Azur, nous avons pour mission d'aider les entreprises à exploiter tout le potentiel de l'IA générative pour innover, gagner en efficacité et se développer.</p>
      </section>

      <section className="philosophy">
        <h2>Notre philosophie</h2>
        <p>Chez BrainCorpAI, nous croyons fermement à l'avenir radieux de l'intelligence artificielle générative. Nous envisageons un futur où l'IA générative est omniprésente, propulsée par des solutions cloud plutôt que par des systèmes locaux. Cette vision s'appuie sur la flexibilité, l'évolutivité et l'accessibilité que le cloud offre, permettant ainsi des innovations plus rapides et une adoption plus large.</p>
      </section>

      <section className="leadership">
        <h2>Leadership d'OpenAI</h2>
        <p>Nous reconnaissons OpenAI comme le pionnier de l'IA générative. Son leadership continu et ses avancées révolutionnaires dans le domaine façonnent notre approche et notre admiration pour leurs travaux. En tant qu'admirateurs et utilisateurs de leurs technologies, nous nous inspirons de leur exemple pour repousser les limites de ce que l'IA peut réaliser.</p>
      </section>

      <section className="expertise">
        <h2>Expertise unique</h2>
        <p>Notre Chief Science Officer, un doctorant en neurosciences computationnelles ancien ingénieur de recherche CNRS, apporte une expertise unique à notre équipe. Spécialisé en IA et LLMOPS, son savoir-faire enrichit notre capacité à développer des solutions de pointe en IA générative et à rester à la pointe de l'innovation technologique.</p>
      </section>

      <section className="innovation">
        <h2>Engagement envers l'innovation</h2>
        <p>Depuis 2019, nous nous consacrons à l'étude et au développement des modèles de langage. Notre veille technologique est rigoureuse, nous permettant de rester à l'avant-garde des développements dans le domaine de l'IA. Cette expertise alimente directement la qualité et l'innovation de nos produits, notamment notre SaaS d'automatisation de réponses, Unanimity AI, qui utilise des agents intelligents fine-tunés pour répondre aux besoins spécifiques de chaque entreprise.</p>
      </section>

      <section className="vision">
        <h2>Vision pour la France</h2>
        <p>Nous aspirons à ce que la France devienne un leader dans le domaine de l'IA générative. Nous sommes déterminés à contribuer à l'avancement de notre pays dans ce domaine, en comblant le retard en matière d'innovation et de technologie. Notre mission est de propulser la France au premier plan de l'IA, en cultivant un écosystème d'innovation et en formant les talents de demain.</p>
      </section>

      <section className="security">
        <h2>Engagement envers la RGPD et la sécurité des données</h2>
        <p>À BrainCorpAI, nous prenons très au sérieux la sécurité des données de nos clients et le respect de la réglementation RGPD. Nous comprenons les préoccupations liées à l'utilisation de solutions basées sur le cloud, et nous nous engageons à fournir un environnement sécurisé et conforme. Nos infrastructures cloud sont conçues pour garantir l'intégrité, la confidentialité et la disponibilité des données.</p>
        <p>Nous adoptons une approche multicouche pour la sécurité, qui comprend le cryptage des données en transit et au repos, l'utilisation de réseaux virtuels privés, et la mise en œuvre de contrôles d'accès stricts. De plus, nous travaillons en étroite collaboration avec des fournisseurs de cloud de renommée mondiale qui sont également engagés à respecter les normes de sécurité les plus élevées et les réglementations en vigueur.</p>
      </section>

      <section className="cta">
        <h2>Prêt à révolutionner votre entreprise avec l'IA générative ?</h2>
        <Link to="/contact" className="button">
          <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
    </div>
  );
};

export default About;
