import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import logo250 from '../assets/logos/logofooter.webp';
import logo45 from '../assets/logos/logo45.png';
const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="contact-info">
          <h3>Contact</h3>
          <p>BrainCorpAI</p>
          <p>3 passage du Docteur Calmette</p>
          <p>06800 Cagnes sur mer</p>
          <p>France</p>
          <p>Email: contact@braincorpai.com</p>
          <p>Téléphone: +33 6 83 77 03 55</p>
        </div>

        <div className="social-media">
          <h3>Suivez-nous</h3>
          <ul>
            <li><a href="https://www.linkedin.com/company/91507583" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://twitter.com/braincorpai" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.instagram.com/braincorpai" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>

        <div className="legal-links">
          <h3>Informations légales</h3>
          <ul>
            <li><Link to="/privacy-policy">Politique de confidentialité</Link></li>
            <li><Link to="/terms-of-service">Conditions d'utilisation</Link></li>
            <li><Link to="/legal">Mentions légales</Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
      <div className="logo-container">
          <img src={logo250} alt="BrainCorpAI logo" />
        </div>
        <p>&copy; {new Date().getFullYear()} BrainCorpAI. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
