// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #000;
    color: #fff;
    padding: 40px 20px;
    margin-top: 100px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content h3 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #ff00ff;
  }
  
  .footer-content p,
  .footer-content ul {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .footer-content ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-content a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-content a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 40px;
    font-size: 14px;
  }
  
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  
    .footer-content > div {
      margin-bottom: 30px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,cAAc;EAChB;;EAEA;;IAEE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE;MACE,sBAAsB;IACxB;;IAEA;MACE,mBAAmB;IACrB;EACF","sourcesContent":["footer {\n    background-color: #000;\n    color: #fff;\n    padding: 40px 20px;\n    margin-top: 100px;\n  }\n  \n  .footer-content {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    max-width: 1200px;\n    margin: 0 auto;\n  }\n  \n  .footer-content h3 {\n    font-size: 20px;\n    margin-bottom: 20px;\n    color: #ff00ff;\n  }\n  \n  .footer-content p,\n  .footer-content ul {\n    font-size: 16px;\n    margin-bottom: 10px;\n  }\n  \n  .footer-content ul {\n    list-style: none;\n    padding: 0;\n  }\n  \n  .footer-content a {\n    color: #fff;\n    text-decoration: none;\n  }\n  \n  .footer-content a:hover {\n    text-decoration: underline;\n  }\n  \n  .footer-bottom {\n    text-align: center;\n    margin-top: 40px;\n    font-size: 14px;\n  }\n  \n  @media screen and (max-width: 768px) {\n    .footer-content {\n      flex-direction: column;\n    }\n  \n    .footer-content > div {\n      margin-bottom: 30px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
