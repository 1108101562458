import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo250 from '../assets/logos/logoheader.webp';
import './Header.css';
import useOutsideClick from './useOutsideClick';

const Header = () => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const submenuRef = useRef(null);
    const mobileMenuRef = useRef(null);
    const location = useLocation(); // This hook returns the location object that represents the current URL.

    const toggleSubmenu = () => setIsSubmenuOpen(!isSubmenuOpen);
    const closeSubmenu = () => setIsSubmenuOpen(false);
    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
    const closeMobileMenu = () => setIsMobileMenuOpen(false);

    useEffect(() => {
        // Scrolls to the top every time the location changes.
        window.scrollTo(0, 0);
    }, [location]); // Dependency array with location ensures this runs only when location changes.

    return (
        <header>
            <div className="logo-container">
                <img src={logo250} alt="BrainCorpAI logo" />
            </div>
            <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                &#9776; {/* Unicode for menu icon */}
            </div>
            <nav className={`desktop-menu ${isMobileMenuOpen ? 'hide' : ''}`}>
                <ul>
                    <li>
                        <Link to="/" onClick={closeMobileMenu}>Accueil</Link>
                    </li>
                    <li className="submenu-parent">
                        <span className="header-span" onClick={toggleSubmenu}>Services</span>
                        <ul className={`submenu ${isSubmenuOpen ? 'open' : ''}`} ref={submenuRef}>
                            <li><Link to="/consulting" onClick={closeSubmenu}>Consulting</Link></li>
                            <li><Link to="/solutions" onClick={closeSubmenu}>Intégration</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/unanimityai" onClick={closeMobileMenu}>Unanimity</Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={closeMobileMenu}>Qui sommes-nous</Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={closeMobileMenu}>Contact</Link>
                    </li>
                </ul>
            </nav>
            <div ref={mobileMenuRef} className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <Link to="/" onClick={closeMobileMenu}>Accueil</Link>
                    </li>
                    <li>
                        <span className="header-span" onClick={toggleSubmenu}>Services</span>
                        <ul className={`submenu ${isSubmenuOpen ? 'open' : ''}`}>
                            <li><Link to="/consulting" onClick={closeMobileMenu}>Consulting</Link></li>
                            <li><Link to="/solutions" onClick={closeMobileMenu}>Intégration</Link></li>
                        </ul>
                    </li>
                    <li>
                        <Link to="/unanimityai" onClick={closeMobileMenu}>Unanimity</Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={closeMobileMenu}>Qui sommes-nous</Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={closeMobileMenu}>Contact</Link>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
