import React from 'react';
import { Link } from 'react-router-dom';
import Unanimity from '../assets/images/unanimity.png';
import './Solutions.css';
import logo250 from '../assets/logos/logo250.webp';

const Solutions = () => {
  return (
    <div>
      <section className="hero">
      <img src={logo250} alt="BrainCorpAI logo" className="hero-logo" />
        <h1>Nos solutions d'IA générative</h1>
        <p>Découvrez comment nos solutions d'IA générative sur mesure peuvent transformer votre entreprise, de l'automatisation des processus à la création de nouvelles expériences client.</p>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
      <section className="generative-ai">
        <h2>L'IA générative, une révolution technologique</h2>
        <p>L'IA générative désigne une classe de modèles d'IA capables de générer de nouveaux contenus (textes, images, vidéos, code...) à partir d'exemples et de directives. Cette approche révolutionne de nombreux domaines, de la création de contenu à l'automatisation des processus en passant par l'analyse de données.</p>
        <p>Chez BrainCorpAI, nous exploitons tout le potentiel de l'IA générative pour créer des solutions innovantes et à forte valeur ajoutée pour nos clients.</p>
        <h2>Les grands modèles de langage (LLM), clé de voûte de l'IA générative</h2>
        <p>Les grands modèles de langage (LLM) sont des modèles d'IA entraînés sur d'immenses corpus de textes, capables de comprendre et de générer du langage naturel de façon très performante. Ils sont à la base de nombreuses applications d'IA générative, des chatbots aux assistants de rédaction en passant par la traduction automatique.</p>
        <p>Chez BrainCorpAI, nous exploitons les LLM les plus avancés (GPT-4, Claude 3, CommandR+) et les techniques de fine-tuning et de prompt engineering pour adapter ces modèles à vos données et à vos cas d'usage spécifiques. Nous veillons à optimiser la performance et la qualité des résultats tout en garantissant la sécurité et la confidentialité de vos données.</p>
      </section>


      <section className="closed-source">
        <h2>Modèles closed-source : performance, scalabilité et sécurité pour l'entreprise</h2>
        <p>Pour déployer des solutions d'IA générative à l'échelle de l'entreprise, nous privilégions les modèles closed-source des grands fournisseurs cloud (OpenAI, Anthropic, Google...). Ces modèles présentent en effet plusieurs avantages clés par rapport aux infrastructures locales :</p>
        <ul>
            <li>
            <strong>Coût initial et maintenance réduits :</strong> L'utilisation de services cloud élimine les coûts élevés d'investissement initial et de maintenance continue associés aux infrastructures locales, tout en offrant une puissance de calcul à la demande.
            </li>
            <li>
            <strong>Accès aux dernières avancées technologiques :</strong> Les fournisseurs de cloud mettent régulièrement à jour leurs services pour offrir les technologies d'IA les plus avancées et les mesures de sécurité les plus récentes, sans effort supplémentaire pour votre entreprise.
            </li>
            <li>
            <strong>Flexibilité et scalabilité optimales :</strong> Le cloud permet d'adapter instantanément les ressources informatiques en fonction des besoins fluctuants de votre entreprise, sans interruption de service ni investissement matériel supplémentaire.
            </li>
            <li>
            <strong>Expertise technique et support 24/7 :</strong> Les fournisseurs de cloud disposent d'équipes d'experts spécialisés et offrent un support technique continu, réduisant ainsi la pression sur vos équipes internes.
            </li>
            <li>
            <strong>Sécurité de pointe :</strong> Les fournisseurs de cloud investissent massivement dans des mesures de sécurité avancées, des certifications de conformité et des protocoles de protection sophistiqués, souvent plus robustes que ce que les entreprises peuvent mettre en place en interne.
            </li>
            <li>
            <strong>Résilience et récupération en cas de désastre :</strong> Le cloud offre des options de sauvegarde et de récupération robustes et rentables, essentielles pour minimiser les temps d'arrêt et protéger les données en cas d'incident.
            </li>
        </ul>
        </section>
      <section className="case-study">
      <div className="case-study-content">
        <h2>Des solutions sur mesure pour votre entreprise</h2>
        <p>Chez BrainCorpAI, nous savons que chaque entreprise est unique, avec ses propres enjeux, objectifs et contraintes. C'est pourquoi nous ne proposons pas de solution "one-size-fits-all", mais nous travaillons main dans la main avec vous pour concevoir et déployer une solution d'IA générative parfaitement adaptée à vos besoins.</p>
        <p>Notre équipe d'experts en IA, data science et ingénierie logicielle collabore étroitement avec vous pour :</p>
        <ul>
          <li>Comprendre votre activité, vos processus et vos défis spécifiques</li>
          <li>Identifier les cas d'usage de l'IA générative les plus pertinents et à forte valeur ajoutée pour votre entreprise</li>
          <li>Concevoir une solution sur mesure intégrant les modèles, algorithmes et fonctionnalités les plus adaptés</li>
          <li>Déployer et intégrer cette solution de manière fluide dans votre environnement technologique et métier</li>
          <li>Assurer le support, la maintenance et l'évolution continue de la solution pour maximiser son ROI dans la durée</li>
        </ul>
        <p>Avec BrainCorpAI, vous bénéficiez d'un partenaire expert et à l'écoute, qui met toute son expertise au service de votre réussite.</p>
        </div>
      </section>


      <section className="cta">
        <h2>Prêt à révolutionner votre entreprise avec l'IA générative ?</h2>
        <Link to="/contact" className="button">
        <button className="hero-button">Contactez-nous !</button>
        </Link>
      </section>
    </div>
  );
};

export default Solutions;